import React from "react"
import { Box } from "@material-ui/core"

import { MainSection } from "./sections"

const Tech: React.FC = () => {
  return (
    <Box>
      <MainSection />
    </Box>
  )
}

export default Tech
