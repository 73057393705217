import React from "react"
import {
  Box,
  useMediaQuery,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import cx from "classnames"
import ReactPlayer from "react-player"

import { useIsDarkMode } from "state/user/hooks"

import { FeatureBox } from "components/Box"

import ICON_PLAY from "assets/icons/video-play.svg"
import BG_POSTER from "assets/backgrounds/video-poster.png"
import BG_LEFT from "assets/backgrounds/about-bg.png"
import BG_RIGHT from "assets/backgrounds/cyan-gradient.png"
import { ArdanaFeatures } from "data"
import { GradientButton } from "components"

const aboutVideo = "https://www.youtube.com/watch?v=pn8OiMxToV8"

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  bg: {
    width: "100%",
    background: ` url(${BG_LEFT}) top left no-repeat,
                  url(${BG_RIGHT}) bottom -200px right -500px no-repeat`,
    backgroundSize: "contain",
    paddingTop: "120px",
    paddingBottom: "200px",

    [breakpoints.down("xs")]: {
      paddingBottom: "100px",
      backgroundPositionY: "250px, 1000px",
      textAlign: "center",
    },
  },

  title: {
    lineHeight: "100%",
    marginBottom: "30px",

    [`& span`]: {
      color: palette.secondary.main,
    },

    [breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },

  content: {
    lineHeight: "26px",

    [breakpoints.down("xs")]: {
      lineHeight: "18px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },

  socialIconLink: {
    "cursor": "pointer",
    "color": palette.primary.main,

    "&:hover path": {
      fill: palette.secondary.main,
    },
  },

  aboutVideo: {
    "position": "relative",
    "& > div": {
      "display": "flex",

      "& > video": {
        objectFit: "cover",
      },
    },
    "& > div > video, & .react-player__preview, & iframe": {
      borderRadius: "10px",
    },
    [breakpoints.down("xs")]: {
      marginTop: "20px",
      marginLeft: "-16px",
      marginRight: "-16px",
    },
  },

  playIcon: {
    background: `url(${BG_POSTER}) center center no-repeat`,
    backgroundSize: "cover",
    borderRadius: "10px",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  photo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    [breakpoints.down("xs")]: {
      width: "60px",
    },
  },
}))

const AboutSection: React.FC = () => {
  const { breakpoints } = useTheme()
  const dark = useIsDarkMode()
  const mobile = useMediaQuery(breakpoints.down("xs"))
  const classes = useStyles({ dark, mobile })

  return (
    <Box className={classes.bg}>
      <Container>
        <Box>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h3"
                component="h3"
                className={cx(classes.title)}
              >
                What is <span>Ardana</span>?
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                className={cx(classes.content)}
              >
                Ardana is a decentralized stablecoin hub which will bring the
                necessary DeFi primitives needed to bootstrap & maintain any
                economy to Cardano.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={cx(classes.aboutVideo)}>
                <ReactPlayer
                  url={aboutVideo}
                  playing={true}
                  loop={false}
                  light={BG_POSTER}
                  width="100%"
                  // height="100%"
                  height={!mobile ? "300px" : "250px"}
                  controls
                  playIcon={
                    <Box className={cx(classes.playIcon)}>
                      <GradientButton
                        width={!mobile ? 145 : 81}
                        height={!mobile ? 145 : 81}
                      />
                      <img
                        className={cx(classes.photo)}
                        src={ICON_PLAY}
                        alt="playIcon"
                        width="100px"
                      />
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={!mobile ? "200px" : "50px"}></Box>

        <Grid container spacing={3} alignItems="stretch">
          {ArdanaFeatures.map((feature, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={3}
              style={{
                display: "flex",
                alignItems: "stretch",
                flexFlow: "column",
              }}
            >
              <FeatureBox
                image={feature.image}
                title={feature.title}
                content={feature.content}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default AboutSection
